window.axios = require("axios");
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
import Snackbar from "node-snackbar";
import 'node-snackbar/dist/snackbar.min.css';

const loader = `<?xml version="1.0" encoding="utf-8"?>
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: none; display: block; shape-rendering: auto; position: absolute; left: 0; top: 0; bottom: 0; right: 0;" width="40px" height="40px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
<g transform="rotate(0 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.9166666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(30 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.8333333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(60 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.75s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(90 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.6666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(120 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5833333333333334s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(150 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.5s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(180 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.4166666666666667s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(210 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.3333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(240 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.25s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(270 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.16666666666666666s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(300 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="-0.08333333333333333s" repeatCount="indefinite"></animate>
  </rect>
</g><g transform="rotate(330 50 50)">
  <rect x="48.5" y="24" rx="0" ry="0" width="3" height="12" fill="#ffffff">
    <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animate>
  </rect>
</g></svg>`;
document.addEventListener("DOMContentLoaded", function() {

    window.axios.defaults.headers.common[
        "X-CSRF-TOKEN"
    ] = document
        .querySelector('meta[name="csrf-token"')
        .getAttribute("content");
    const forms = document.querySelectorAll(".form");

    [...forms].forEach(form => {
        const button = form.querySelector(".submit");
        const buttonText = button.innerHTML;
        button.addEventListener("click", e => {
            e.preventDefault();
            [...document.querySelectorAll('.error-message')].forEach($error => {
              $error.remove();
            });
            grecaptcha.ready(() => {
                grecaptcha
                    .execute(button.dataset.sitekey, {
                        action: "submit"
                    })
                    .then(token => {
                        button.innerHTML = loader;
                        button.disabled = true;
                        const formdata = new FormData(form);
                        formdata.append('g-recaptcha-response', token);
                        axios
                            .post(form.getAttribute("action"), formdata)
                            .then(response => {
                                button.innerHTML = buttonText;
                                button.disabled = false;
                                Snackbar.show({
                                    text: response.data.success,
                                    pos: "top-center",
                                    actionText: "Отлично!"
                                });
                                if(button.dataset.onsuccess) {
                                  window[button.dataset.onsuccess](response.data, formdata);
                                }
                            })
                            .catch(error => {
                                if(button.dataset.onerror) {
                                    window[button.dataset.onerror](error.response, formdata);
                                }
                                button.innerHTML = buttonText;
                                button.disabled = false;
                                if(error && error.response && error.response.status == 422) {
                                    const errors = error.response.data.errors;
                                    const keys = Object.keys(errors);
                                    for(let key of keys) {
                                        const elem = form.querySelector(`.error-${key}`);
                                        const $error = document.createElement('div');
                                        $error.classList.add('text-sm');
                                        $error.classList.add('text-red-500');
                                        $error.classList.add('error-message');
                                        $error.innerText = errors[key];
                                        elem.insertAdjacentElement("beforeend", $error);
                                    }
                                }
                            });
                    });
            });
        });
    });
});
